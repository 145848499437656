import './App.css';
import "./card.css"
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import ImpexLayoutFn from './components/layouts/ImpexLayout';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          
          {/* Impex Layout */}
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/*" element={<ImpexLayoutFn />} />

          {/* 404 bugfix: redirect to home page */}
          <Route path="*" element={<Navigate to="/home" />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
