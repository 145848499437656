import { useEffect, useState } from "react";
import { Link } from "react-scroll";


export let ImpexHeader = () => {

	const [isSticky, setIsSticky] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);
	//const history = useHistory();

	const handleScroll = () => {
		setIsSticky(window.pageYOffset >= getOffset());
		setIsScrolled(window.pageYOffset > 15);
	};

	const getOffset = () => {
		const navbarElement = document.querySelector('.navbar');
		return navbarElement ? navbarElement.getBoundingClientRect().top : 0;
	};

	const handleToggleClick = () => {
		const navbarCollapse = document.getElementById("navbarCollapse")?.classList.contains("show");

		if (navbarCollapse) {
			// Handle the collapse logic
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);


	return (

		<>
			<div className={`navbar navbar-expand-lg navbar-dark fixed-top sticky scrolled`}>
				<div className="container">

					{/* Web Display */}
					<div className="w-50 d-none d-md-block">
						<h1 className="text-center">
							<a href="/home" className="navbar-brand d-flex align-items-center bg-transparent">
								<img
									className="img-fluid Logo_theX me-3"
									src="../assets/images/impexlogo.png"
									alt="xlinezimpex logistics"
								/>
							</a>
						</h1>
					</div>

					{/* Mobile Display */}
					<div className="w-75 d-block d-md-none">
						<h1 className="text-center">
							<a href="/home" className="navbar-brand d-flex align-items-center bg-transparent">
								<img
									className="img-fluid Logo_theX me-3"
									src="../assets/images/impexlogo.png"
									alt="xlinezimpex logistics"
								/>
							</a>
						</h1>
					</div>


					<button
						className="navbar-toggler ml-auto"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarCollapse"
						aria-controls="navbarCollapse"
						aria-expanded="false"
						aria-label="Toggle navigation"
						style={{ color: '#a40000' }}
						onClick={handleToggleClick}
					>
						<span className="navbar-toggler-icon"></span>
					</button>

					<div className="collapse navbar-collapse Navcss" id="navbarCollapse">
						<div className="navbar-nav mx-auto pe-4 py-3 py-lg-0">
							<Link className="nav-item nav-link" to="home-xlinez">Home</Link>
							<Link className="nav-item nav-link" to="about-us" smooth={true} duration={100} offset={-100}>About Us</Link>
							<Link className="nav-item nav-link" to="our-products" smooth={true} duration={100} offset={-100}>Products</Link>
							<Link className="nav-item nav-link" to="contact-us" smooth={true} duration={100} offset={-110}>Contact Us</Link>
							<Link className="nav-item nav-link" to="faq" smooth={true} duration={100} offset={-120}>FAQ</Link>

							{/* <li className="nav-item dropdown">
								<a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									Products
								</a>
								<div className="dropdown-menu" aria-labelledby="navbarDropdown">
									<a className="dropdown-item" href="/home">Banana Powder</a>
									<a className="dropdown-item" href="/home">Red Onion</a>
									<a className="dropdown-item" href="/home">Maize Seeds</a>
									<a className="dropdown-item" href="/home">Millet Seeds</a>
								</div>
							</li> */}

							{/* <Link className="nav-item nav-link" to={'/impex'}>Blog</Link>
							<Link className="nav-item nav-link" to={'/impex'}>Gallery</Link>
							<Link className="nav-item nav-link" to={'/impex'}>Our Services</Link> */}

						</div>
					</div>
				</div>
			</div>

		</>
	)
}