import { Component } from "react";
import { Link } from "react-scroll";

export class ImpexHome extends Component {
  render() {
    return (
      <>
        <section id="home-xlinez" className="container-fluid p-0">
          <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">

                <video muted loop autoPlay id="myVideo">
                  <source src="../../assets/video/Aerial_view.mp4" type="video/mp4" />
                </video>

                <div className="carousel-caption content">
                  <div className="container" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                    <div className="row justify-content-center">
                      <div className="col-lg-7 pt-5">


                        <h1 className="display-4 text-white mb-4 animated slideInDown" >
                          {/* WELCOME TO X LINEZ TRUST US , BEST  X   */}
                          Welcome to <span>X Linez Impex</span>
                        </h1>
                        <p className="fs-5 mb-4 pb-2 mx-sm-5 animated slideInDown">
                          Your Trusted Partner in Global Trade
                        </p>

                        {/* <Link className="my-btn animated slideInDown" to={'/impex'}></Link> */}
                        <Link className="my-btn animated slideInDown" to="contact-us" smooth={true} duration={100} offset={-110}>Get Start With Us</Link>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section style={{ backgroundColor: "#a40000", color: "white" }}>
          <div className="container py-5">
            <div className="row">
              <div className="col-md-12 text-center">
                <p className="fw-bold lead mb-2" data-aos="fade-up">We're a team of experts in global trade, specializing in import and export solutions.</p>
                <p data-aos="fade-up">At X Linez Impex, we specialize in connecting businesses worldwide through efficient and reliable import and export services. With a strong network of partners and a commitment to excellence, we help you navigate the complexities of international trade seamlessly.</p>
              </div>
            </div>
          </div>
        </section>

        <section id="about-us" className="container my-5 py-5">
          <div className="row">
            <div className="col-lg-8 mx-auto text-center">
              <h3 className="heading-1 mb-4" data-aos="fade-right">About Us</h3>
              <p className="lead mb-4" data-aos="fade-up">
                While we are new to the industry, we bring a strong commitment to excellence and innovation in global trade. Our dedicated team is focused on providing personalized solutions tailored to your business needs, ensuring smooth and successful transactions every time.
              </p>
              <ul className="list-unstyled" data-aos="fade-up">
                <li className="mb-3">
                  <strong>Our Mission:</strong> To facilitate seamless global trade by providing top-notch import and export services, ensuring quality, reliability, and customer satisfaction.
                </li>
                <li>
                  <strong>Our Vision:</strong> To be a global leader in the import and export industry, recognized for our commitment to excellence, sustainability, and innovation.
                </li>
              </ul>
              {/* <a className="btn btn-primary mt-4" href="#" data-aos="fade-up">Learn More About Us</a> */}
            </div>
          </div>
        </section>

        <section id="our-services" className="container p-5">
          <h3 className="text-center mb-4 heading-1" data-aos="fade-up">Our Services</h3>
          <p className="text-center mb-5" data-aos="fade-up">
            We offer a wide range of services to meet all your import and export needs:
          </p>
          <div className="row">
            <div className="col-md-6 mb-4" data-aos="flip-up">
              <div className="service-box p-4">
                <h3>Import Services</h3>
                <ul>
                  <li>Sourcing and Procurement</li>
                  <li>Customs Clearance</li>
                  <li>Logistics and Transportation</li>
                  <li>Quality Control and Inspection</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 mb-4" data-aos="flip-up">
              <div className="service-box p-4">
                <h3>Export Services</h3>
                <ul>
                  <li>Market Research and Analysis</li>
                  <li>Documentation and Compliance</li>
                  <li>Shipping and Freight Forwarding</li>
                  <li>Export Financing and Insurance</li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="text-center">
            <a className="btn btn-secondary" href="#">Explore Our Services</a>
          </div> */}
        </section>

        <section id="our-products" className="p-5">
          <h3 className="text-center sec-heading heading-1" data-aos="fade-up">
            Our Products
          </h3>
          <section className="md-container mx-auto card-list" data-aos="zoom-in">

            <article className="card">
              <div className="mt-4 mx-auto">
                <img className="img-fluid" src="\assets\images\image.webp" alt="" />
              </div>
              <header className="card-header">
                <h2>Fresh Fruits</h2>
              </header>
            </article>

            <article className="card">
              <div className="mt-4 mx-auto">
                <img className="img-fluid" src="\assets\images\image1.webp" alt="" />
              </div>
              <header className="card-header">
                <h2>Fresh Vegetables</h2>
              </header>
            </article>

            <article className="card">
              <div className="mt-4 mx-auto">
                <img className="img-fluid" src="\assets\images\image2.webp" alt="" />
              </div>
              <header className="card-header">
                <h2>Millets</h2>
              </header>
            </article>

            <article className="card">
              <div className="mt-4 mx-auto">
                <img className="img-fluid" src="\assets\images\image3.webp" alt="" />
              </div>
              <header className="card-header">
                <h2 className="heading-1">Rice Varieties</h2>
              </header>
            </article>

          </section>
        </section>

        <section id="industries-we-serve" className="container p-5" data-aos="fade-up">
          <h3 className="text-center mb-4 heading-1">Industries We Serve</h3>
          <p className="text-center mb-5">
            We cater to a wide range of industries, including but not limited to:
          </p>
          <ul className="industries-list">
            <li className="industry-item" data-aos="fade-right" data-aos-delay="100">
              Agriculture and Food Products
            </li>
            <li className="industry-item" data-aos="fade-right" data-aos-delay="200">
              Automotive and Machinery
            </li>
            <li className="industry-item" data-aos="fade-right" data-aos-delay="300">
              Electronics and Technology
            </li>
            <li className="industry-item" data-aos="fade-right" data-aos-delay="400">
              Textiles and Apparel
            </li>
            <li className="industry-item" data-aos="fade-right" data-aos-delay="500">
              Healthcare and Pharmaceuticals
            </li>
          </ul>

          {/* <div className="text-center">
            <a className="btn btn-secondary" href="#">See All Industries</a>
          </div> */}
        </section>

        <section id="sustainability" className="container my-5" data-aos="fade-up">
          <h3 className="text-center mb-4 heading-1" data-aos="fade-down">Sustainability Commitment</h3>
          <p className="text-center mb-5" data-aos="fade-down" data-aos-delay="100">
            At X linez Impex, we are committed to sustainable and ethical trading practices. We strive to minimize our environmental impact and ensure that our operations support the well-being of communities and ecosystems around the world.
          </p>
          <ul className="sustainability-list">
            <li className="sustainability-item" data-aos="fade-right" data-aos-delay="200">
              <strong>Eco-Friendly Packaging:</strong> Utilizing sustainable packaging solutions.
            </li>
            <li className="sustainability-item" data-aos="fade-right" data-aos-delay="300">
              <strong>Fair Trade Practices:</strong> Ensuring fair wages and ethical treatment of workers.
            </li>
            <li className="sustainability-item" data-aos="fade-right" data-aos-delay="400">
              <strong>Carbon Footprint Reduction:</strong> Implementing measures to reduce carbon emissions.
            </li>
          </ul>
          {/* <div className="text-center mt-4" data-aos="fade-up" data-aos-delay="500">
            <a className="btn btn-secondary" href="#">Learn More About Our Commitment</a>
          </div> */}
        </section>


        {/* <section id="testimonials" className="container my-5" data-aos="fade-up">
          <h2 className="text-center mb-5 heading-1" data-aos="fade-down">What Our Clients Say</h2>

          <div className="row">
            <div className="col-md-6" data-aos="flip-left" data-aos-delay="100">
              <div className="testimonial-card">
                <p className="testimonial-text">"Working with Xlinez Impex has been a game-changer for our business. Their expertise and support have helped us expand our reach and grow our operations internationally."</p>
                <p className="testimonial-client">[Client Name, Company]</p>
              </div>
            </div>

            <div className="col-md-6" data-aos="flip-right" data-aos-delay="200">
              <div className="testimonial-card">
                <p className="testimonial-text">"Xlinez Impex provides top-quality products and exceptional customer service. We couldn’t be happier with our partnership."</p>
                <p className="testimonial-client">[Client Name, Company]</p>
              </div>
            </div>
          </div>
        </section> */}



        {/*         <section id="blog" className="container my-5" data-aos="fade-up">
          <h2 className="text-center mb-4 heading-1" data-aos="fade-down">Latest News &amp; Insights</h2>
          <p className="text-center mb-5" data-aos="fade-down" data-aos-delay="100">
            Keep up with the latest trends and insights in the global trade industry. Our blog features articles, news, and updates that provide valuable information to help you stay ahead in the market.
          </p>
          <div className="text-center" data-aos="zoom-in" data-aos-delay="200">
            <a className="btn btn-primary" href="#">Read Our Blog</a>
          </div>
        </section> */}

        <section id="contact-us" className="container my-5" data-aos="fade-up">
          <h3 className="text-center mb-4 heading-1" data-aos="fade-down">Get in Touch</h3>
          <p className="text-center mb-5" data-aos="fade-down" data-aos-delay="100">
            Ready to grow your business globally? Contact us today to learn more about how X linez Impex can support your international trade operations.
          </p>

          <div className="row text-center mb-4">
            <div className="col-md-4" data-aos="fade-right" data-aos-delay="200">
              <div className="contact-info">
                <i className="fas fa-envelope fa-2x mb-3"></i>
                <p><strong>Email:</strong></p>
                <p>
                  <a className="link-secondary text-decoration-none" href="mailto:info@thexlinez.com">
                    info@thexlinez.com
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-4" data-aos="fade-right" data-aos-delay="400">
              <div className="contact-info">
                <i className="fas fa-map-marker-alt fa-2x mb-3"></i>
                <p><strong>Address:</strong></p>
                <p>19/87 D1, First Floor, Pulluvilai, Erumbukadu Post, Nagercoil, Kanniyakumari, Tamil Nadu-629 004.</p>
              </div>
            </div>
            <div className="col-md-4" data-aos="fade-right" data-aos-delay="300">
              <div className="contact-info">
                <i className="fas fa-phone fa-2x mb-3"></i>
                <p><strong>Phone:</strong></p>
                <p>
                  <a className="link-secondary text-decoration-none"
                    href="tel:+919566650400"
                  >(+91) 95666 50400</a>
                </p>

              </div>
            </div>
          </div>

          {/* <div className="text-center mt-4" data-aos="zoom-in" data-aos-delay="500">
            <Link  className="btn btn-primary" to={"contactus"}>Contact Us</Link>
          </div> */}
        </section>



        <section id="faq" className="container my-5" data-aos="fade-up">
          <h3 className="text-center mb-5 heading-1" data-aos="fade-down">Frequently Asked Questions</h3>

          <div className="accordion" id="faqAccordion">
            <div className="accordion-item" data-aos="fade-right" data-aos-delay="100">
              <h5 className="accordion-header" id="headingOne">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  What products do you specialize in?
                </button>
              </h5>
              <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#faqAccordion">
                <div className="accordion-body">
                  We specialize in fresh fruits, vegetables, millets, and rice varieties.
                </div>
              </div>
            </div>

            <div className="accordion-item" data-aos="fade-right" data-aos-delay="200">
              <h5 className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  How can I place an order?
                </button>
              </h5>
              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faqAccordion">
                <div className="accordion-body">
                  You can place an order by contacting us via email, phone, or through our website's contact form.
                </div>
              </div>
            </div>

            <div className="accordion-item" data-aos="fade-right" data-aos-delay="300">
              <h5 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Do you offer customized solutions?
                </button>
              </h5>
              <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faqAccordion">
                <div className="accordion-body">
                  Yes, we provide tailored solutions to meet the specific needs of your business.
                </div>
              </div>
            </div>
          </div>

          {/* <div className="text-center mt-4" data-aos="zoom-in" data-aos-delay="400">
            <a className="btn btn-primary" href="#">See More FAQs</a>
          </div> */}
        </section>









        {/*         <section id="what-i-do" className="container my-5" data-aos="fade-up">
          <h1 className="text-center mb-5" data-aos="fade-down">WHAT I DO</h1>
          <div className="row">

            <div className="col-md-3 col-sm-6 mb-4" data-aos="zoom-in" data-aos-delay="100">
              <div className="block text-center">
                <img src="import-icon.png" alt="Import Icon" className="img-fluid mb-3" />
                <h2 className="h5">IMPORT</h2>
                <p>Fusce nec tellus sed augue semper porta. Mauris massa, Vestibulum lacinia arcu eget nulla.</p>
                <a href="#" className="btn btn-primary">Read More</a>
              </div>
            </div>

            <div className="col-md-3 col-sm-6 mb-4" data-aos="zoom-in" data-aos-delay="200">
              <div className="block text-center">
                <img src="buy-icon.png" alt="Buy Icon" className="img-fluid mb-3" />
                <h2 className="h5">BUY</h2>
                <p>Fusce nec tellus sed augue semper porta. Mauris massa, Vestibulum lacinia arcu eget nulla.</p>
                <a href="#" className="btn btn-primary">Read More</a>
              </div>
            </div>

            <div className="col-md-3 col-sm-6 mb-4" data-aos="zoom-in" data-aos-delay="300">
              <div className="block text-center">
                <img src="export-icon.png" alt="Export Icon" className="img-fluid mb-3" />
                <h2 className="h5">EXPORT</h2>
                <p>Fusce nec tellus sed augue semper porta. Mauris massa, Vestibulum lacinia arcu eget nulla.</p>
                <a href="#" className="btn btn-primary">Read More</a>
              </div>
            </div>

            <div className="col-md-3 col-sm-6 mb-4" data-aos="zoom-in" data-aos-delay="400">
              <div className="block text-center">
                <img src="export-icon.png" alt="Export Icon" className="img-fluid mb-3" />
                <h2 className="h5">EXPORT</h2>
                <p>Fusce nec tellus sed augue semper porta. Mauris massa, Vestibulum lacinia arcu eget nulla.</p>
                <a href="#" className="btn btn-primary">Read More</a>
              </div>
            </div>

          </div>
        </section> */}





































        {/* <footer className="bg-light py-3">
          <div className="container text-center">
            <p className="m-0">© 2024 Xlinez Impex. All rights reserved.</p>
          </div>
        </footer> */}




        {/* <div>
          <section id="our-products" className="container my-5">
            <h2>Our Products</h2>
            <p>At Xlinez Impex, we pride ourselves on offering a diverse range of high-quality agricultural products to meet the needs of our global clientele.</p>
            <div className="row">
              <div className="col-md-3">
                <h3>Fresh Fruits</h3>
                <ul>
                  <li>Apples</li>
                  <li>Bananas</li>
                  <li>Oranges</li>
                  <li>Mangoes</li>
                </ul>
              </div>
              <div className="col-md-3">
                <h3>Fresh Vegetables</h3>
                <ul>
                  <li>Tomatoes</li>
                  <li>Potatoes</li>
                  <li>Onions</li>
                  <li>Leafy Greens</li>
                </ul>
              </div>
              <div className="col-md-3">
                <h3>Millets</h3>
                <ul>
                  <li>Pearl Millet</li>
                  <li>Finger Millet</li>
                  <li>Foxtail Millet</li>
                  <li>Sorghum</li>
                </ul>
              </div>
              <div className="col-md-3">
                <h3>Rice Varieties</h3>
                <ul>
                  <li>Basmati Rice</li>
                  <li>Jasmine Rice</li>
                  <li>Long Grain Rice</li>
                  <li>Short Grain Rice</li>
                </ul>
              </div>
            </div>
            <a className="btn btn-secondary" href="#">View Our Products</a>
          </section>


          

        </section >






        {/*       <section>

      <div className="wrapper-grey padded">
        <div className="container">
          <h2 className="text-center">Our Products</h2>

          <div className="row">

            <div className="col-xs-12 col-sm-3">
              <div className="card1" style={{ background: 'linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.2)), url("../../assets/images/home-1.jpeg")' }}>
                <div className="card1-category">City</div>
                <div className="card1-description">
                  <h2>home</h2>
                  <p>Lovely house</p>
                </div>
                <img className="card1-user avatar avatar-large w-100" src="../../assets/images/home-1.jpeg" />
                <a className="card1-link" href="#" />
              </div>
            </div>

            <div className="col-xs-12 col-sm-3">
              <div className="card1" style={{ background: 'linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.2)), url("../../assets/images/home-1.jpeg")' }}>
                <div className="card1-category">City</div>
                <div className="card1-description">
                  <h2>home</h2>
                  <p>Lovely house</p>
                </div>
                <img className="card1-user avatar avatar-large w-100" src="../../assets/images/home-1.jpeg" />
                <a className="card1-link" href="#" />
              </div>
            </div>

            <div className="col-xs-12 col-sm-3">
              <div className="card1" style={{ background: 'linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.2)), url("../../assets/images/home-1.jpeg")' }}>
                <div className="card1-category">City</div>
                <div className="card1-description">
                  <h2>Home</h2>
                  <p>Lovely house</p>
                </div>
                <img className="card1-user avatar avatar-large w-100" src="../../assets/images/home-1.jpeg" />
                <a className="card1-link" href="#" />
              </div>
            </div>

            <div className="col-xs-12 col-sm-3">
              <div className="card1" style={{ background: 'linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.2)), url("../../assets/images/home-1.jpeg")' }}>
                <div className="card1-category">City</div>
                <div className="card1-description">
                  <h2>Home</h2>
                  <p>Lovely house</p>
                </div>
                <img className="card1-user avatar avatar-large w-100" src="../../assets/images/home-1.jpeg" />
                <a className="card1-link" href="#" />
              </div>
            </div>

          </div>

        </div>
      </div>




    </section> */}
      </>
    )
  }
}